import {
    Avatar,
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoIosCart } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import avatar1 from "../Assets/Images/profile.webp";
import { IoMdNotifications } from "react-icons/io";
import logo from "../Assets/Images/logo.png";
import "../Pages/home.css";
import { FaUser } from "react-icons/fa6";
import { MdContactSupport } from "react-icons/md";
import { IoLogOut, IoSearchOutline } from "react-icons/io5";
import { SiPivotaltracker } from "react-icons/si";
import { IoMenu } from "react-icons/io5";
import MyAccountModal from "../Pages/MyAccountModal";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Store/authSlice";
import { toast } from "react-toastify";
import { getCartItems } from "../Store/cartSlice";
import { RxCross2 } from "react-icons/rx";
import NavProductModal from "./NavProductModal";
import axios from "axios";
import { baseUrl } from "../Constant_key";
import { BiSolidSearchAlt2 } from "react-icons/bi";
import { GiArchiveRegister } from "react-icons/gi";
import { CiLogin } from "react-icons/ci";

export default function HomeNav() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { partId } = useParams();

    const [isLogin, setIsLogin] = useState(false);
    const [myAccModalOpen, setMyAccModalOpen] = useState(false);
    const [mfgNumber, setMfgNumber] = useState("");
    const [isDetailPage, setIsDetailPage] = useState(false);
    const [SearchModal, setSearchModal] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [partDetails, setPartDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(false);

    const cartItems = useSelector(
        (state) => state?.cartReducer?.items?.items || []
    );

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemButtonClick = () => {
        navigate("/shopping-cart");
    };

    useEffect(() => {
        const res = localStorage.getItem("isLogin");

        if (res == "true") {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }, []);

    const handleLogoutBtn = () => {
        dispatch(logout());
        handleClose();
        toast.success("Logged out successfully");
        navigate("/login");
    };

    const handleMyAccClick = () => {
        handleMyAccClickOpen();
        handleClose();
    };

    const handleMyAccClose = () => {
        setMyAccModalOpen(false);
    };

    const handleMyAccClickOpen = () => {
        setMyAccModalOpen(true);
    };

    useEffect(() => {
        const isPartDetailsPage =
            location.pathname.includes(`/part/${partId}`) ||
            location.pathname.includes(`/shopping-cart`) ||
            location.pathname.includes(`/order-summary`) ||
            location.pathname.includes(`/part-tracker`);
        if (isPartDetailsPage) setIsDetailPage(true);
    }, []);

    useEffect(() => {
        dispatch(getCartItems(navigate));
    }, []);

    const getIpAddress = async () => {
        try {
            const response = await axios.get(
                "https://api.ipify.org?format=json"
            );
            return response.data.ip;
        } catch (error) {
            console.error("Error fetching IP:", error);
            return null;
        }
    };

    const handleSearchClickOpen = async () => {
        if (mfgNumber) {
            setLoading(true);
            try {
                const ip = await getIpAddress();
                const token = await localStorage.getItem("token");
                const data = {
                    partNumber: mfgNumber,
                    ip: ip || "Unknown",
                    token: token,
                };
                const response = await axios.post(
                    `${baseUrl}api/part-details`,
                    data
                );
                setPartDetails(response.data);
                setMfgNumber("");
                setSearchModal(true);
            } catch (error) {
                console.error("Error fetching part details:", error);
                toast.error("Failed to fetch part details. Please try again.");
            } finally {
                setLoading(false);
                setMfgNumber("");
            }
        } else {
            toast.warn("Please enter a MFG part number");
        }
    };

    const handleSearchIconClick = () => {
        setShowSearchInput(!showSearchInput);
    };

    const handleSearchClose = () => {
        setSearchModal(false);
    };

    const toggleDrawer = (newOpen) => () => {
        setDrawerOpen(newOpen);
    };

    const DrawerList = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
        >
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/register")}>
                        <ListItemIcon>
                            <GiArchiveRegister />
                        </ListItemIcon>
                        <ListItemText primary={"Register"} />
                    </ListItemButton>
                </ListItem>
            </List>

            <List>
                <ListItem disablePadding onClick={() => navigate("/login")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <CiLogin />
                        </ListItemIcon>
                        <ListItemText primary={"Login"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div className="mainnavbar">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "15px",
                        }}
                    >
                        <div>
                            <img
                                className="navbarLogo"
                                onClick={() => navigate("/")}
                                src={logo}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                gap: "15px",
                                alignItems: "center",
                            }}
                        >
                            {isLogin ? (
                                <>
                                    {isDetailPage && (
                                        <div className="profileSearchButton">
                                            <div style={{ display: "flex" }}>
                                                <input
                                                    size="small"
                                                    placeholder="Search Manufacturer Part Number"
                                                    value={mfgNumber}
                                                    onChange={(e) =>
                                                        setMfgNumber(
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleSearchClickOpen();
                                                        }
                                                    }}
                                                    className="detailspagesearchbox"
                                                />
                                                <button
                                                    onClick={
                                                        handleSearchClickOpen
                                                    }
                                                    style={{
                                                        width: "4rem",
                                                        borderWidth: "1px",
                                                        cursor: "pointer",
                                                        borderColor: "#16ACE3",
                                                        borderTopRightRadius:
                                                            "6px",
                                                        borderBottomRightRadius:
                                                            "6px",
                                                    }}
                                                >
                                                    <BiSolidSearchAlt2
                                                        size={18}
                                                        style={{
                                                            fontWeight: "600",
                                                        }}
                                                        color="#1F80C0"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {isDetailPage && (
                                        <div className="profileSearchmenu">
                                            <div>
                                                <button
                                                    onClick={
                                                        handleSearchIconClick
                                                    }
                                                    style={{
                                                        width: "3rem",
                                                        padding: "6px",
                                                        borderWidth: "1px",
                                                        cursor: "pointer",
                                                        borderColor: "#16ACE3",
                                                        borderRadius: "6px",
                                                    }}
                                                >
                                                    {showSearchInput ? (
                                                        <RxCross2
                                                            size={18}
                                                            style={{
                                                                fontWeight:
                                                                    "600",
                                                            }}
                                                            color="#1F80C0"
                                                        />
                                                    ) : (
                                                        <BiSolidSearchAlt2
                                                            size={18}
                                                            style={{
                                                                fontWeight:
                                                                    "600",
                                                            }}
                                                            color="#1F80C0"
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    <Button
                                        onClick={() => handleItemButtonClick()}
                                        variant="contained"
                                        className="profileCartButton"
                                    >
                                        <IoIosCart
                                            size={15}
                                            style={{ marginRight: "5px" }}
                                        />
                                        {`${cartItems.length} item(s)`}
                                    </Button>
                                    <div
                                        style={{
                                            display: "flex",
                                            // marginLeft: "1rem",
                                            alignItems: "center",
                                        }}
                                    >
                                        {/* <Badge
                                            badgeContent={0}
                                            color="success"
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                            sx={{
                                                "& .MuiBadge-badge": {
                                                    fontSize: "0.6rem",
                                                    height: "16px",
                                                    position: "absolute",
                                                    top: "0px",
                                                    minWidth: "16px",
                                                },
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <IoMdNotifications
                                                    fontSize={25}
                                                />
                                            </Box>
                                        </Badge> */}

                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}
                                        >
                                            <Tooltip title="Account settings">
                                                <IconButton
                                                    onClick={handleClick}
                                                    size="small"
                                                    // sx={{ ml: 2 }}
                                                    aria-controls={
                                                        open
                                                            ? "account-menu"
                                                            : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    aria-expanded={
                                                        open
                                                            ? "true"
                                                            : undefined
                                                    }
                                                >
                                                    <Avatar
                                                        alt="Remy Sharp"
                                                        style={{
                                                            border: "2px solid #0D17244D",
                                                        }}
                                                        src={avatar1}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Menu
                                            anchorEl={anchorEl}
                                            id="account-menu"
                                            open={open}
                                            onClose={handleClose}
                                            onClick={handleClose}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: "visible",
                                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                    mt: 1.5,
                                                    width: "220px",
                                                    "& .MuiAvatar-root": {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    "&::before": {
                                                        content: '""',
                                                        display: "block",
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor:
                                                            "background.paper",
                                                        transform:
                                                            "translateY(-50%) rotate(45deg)",
                                                        zIndex: 0,
                                                    },
                                                },
                                            }}
                                            transformOrigin={{
                                                horizontal: "right",
                                                vertical: "top",
                                            }}
                                            anchorOrigin={{
                                                horizontal: "right",
                                                vertical: "bottom",
                                            }}
                                        >
                                            <MenuItem
                                                sx={{ fontSize: "13px" }}
                                                onClick={handleMyAccClick}
                                            >
                                                <FaUser
                                                    style={{
                                                        marginRight: "12px",
                                                    }}
                                                />
                                                My Profile
                                            </MenuItem>
                                            <MenuItem
                                                sx={{ fontSize: "13px" }}
                                                className="profileCartMenu"
                                                onClick={handleItemButtonClick}
                                            >
                                                <IoIosCart
                                                    style={{
                                                        marginRight: "12px",
                                                        fontSize: "17px",
                                                    }}
                                                />
                                                Cart
                                            </MenuItem>
                                            {/* <MenuItem
                                                sx={{
                                                    fontSize: "13px",
                                                    paddingTop: "15px",
                                                }}
                                                onClick={handleItemButtonClick}
                                                className="profileCartMenu"
                                            >
                                                <IoIosCart
                                                    style={{
                                                        marginRight: "12px",
                                                        fontSize: "15px",
                                                    }}
                                                />
                                                Cart
                                            </MenuItem> */}
                                            <MenuItem
                                                sx={{ fontSize: "13px" }}
                                                onClick={() => {
                                                    handleClose();
                                                    navigate("/part-tracker");
                                                }}
                                            >
                                                <SiPivotaltracker
                                                    style={{
                                                        marginRight: "12px",
                                                    }}
                                                />
                                                Part Tracker
                                            </MenuItem>
                                            <MenuItem
                                                sx={{ fontSize: "13px" }}
                                                onClick={() => {
                                                    handleClose();
                                                    navigate("/search-history");
                                                }}
                                            >
                                                <BiSolidSearchAlt2
                                                    style={{
                                                        marginRight: "12px",
                                                    }}
                                                />
                                                Part Search History
                                            </MenuItem>
                                            <MenuItem
                                                sx={{ fontSize: "13px" }}
                                                onClick={handleClose}
                                            >
                                                <MdContactSupport
                                                    style={{
                                                        marginRight: "12px",
                                                    }}
                                                />
                                                Support
                                            </MenuItem>
                                            <MenuItem
                                                sx={{ fontSize: "13px" }}
                                                onClick={handleLogoutBtn}
                                            >
                                                <IoLogOut
                                                    style={{
                                                        marginRight: "12px",
                                                    }}
                                                />
                                                Log Out
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="registerLoginOption">
                                        {isDetailPage && (
                                            <div style={{ display: "flex" }}>
                                                <input
                                                    size="small"
                                                    placeholder="Search Manufacturer Part Number"
                                                    value={mfgNumber}
                                                    onChange={(e) =>
                                                        setMfgNumber(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="detailspagesearchbox"
                                                />
                                                <button
                                                    onClick={
                                                        handleSearchClickOpen
                                                    }
                                                    style={{
                                                        width: "4rem",
                                                        borderWidth: "1px",
                                                        cursor: "pointer",
                                                        borderColor: "#16ACE3",
                                                        borderTopRightRadius:
                                                            "6px",
                                                        borderBottomRightRadius:
                                                            "6px",
                                                    }}
                                                >
                                                    <BiSolidSearchAlt2
                                                        size={18}
                                                        style={{
                                                            fontWeight: "600",
                                                        }}
                                                        color="#1F80C0"
                                                    />
                                                </button>
                                            </div>
                                        )}
                                        <Button
                                            onClick={() =>
                                                navigate("/register")
                                            }
                                            className="registerloginbtn"
                                        >
                                            Register
                                        </Button>
                                        <Button
                                            onClick={() => navigate("/login")}
                                            className="registerloginbtn"
                                        >
                                            Login
                                        </Button>
                                    </div>

                                    <div className="registerLoginMenuIcon">
                                        <IoMenu
                                            onClick={toggleDrawer(true)}
                                            fontSize={"24px"}
                                        />
                                    </div>

                                    <Drawer
                                        open={drawerOpen}
                                        onClose={toggleDrawer(false)}
                                    >
                                        {DrawerList}
                                    </Drawer>
                                </>
                            )}
                        </div>
                    </div>

                    {showSearchInput && (
                        <div className={`navbarSearchInput ${showSearchInput ? "show" : ""}`}>
                            <div
                                style={{
                                    marginBottom: "1rem",
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                            >
                                <input
                                    size="small"
                                    placeholder="Search Manufacturer Part Number"
                                    style={{ width: "100%" }}
                                    value={mfgNumber}
                                    onChange={(e) =>
                                        setMfgNumber(e.target.value)
                                    }
                                    className="detailspagesearchbox"
                                />
                                <button
                                    onClick={handleSearchClickOpen}
                                    style={{
                                        width: "4rem",
                                        borderWidth: "1px",
                                        cursor: "pointer",
                                        borderColor: "#16ACE3",
                                        borderTopRightRadius: "6px",
                                        borderBottomRightRadius: "6px",
                                    }}
                                >
                                    <BiSolidSearchAlt2
                                        size={18}
                                        style={{
                                            fontWeight: "600",
                                        }}
                                        color="#1F80C0"
                                    />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <MyAccountModal
                open={myAccModalOpen}
                handleClose={handleMyAccClose}
            />

            <NavProductModal
                mfgNumber={mfgNumber}
                handleClose={handleSearchClose}
                SearchModal={SearchModal}
                loading={loading}
                partDetails={partDetails}
            />
        </>
    );
}
